<template>
  <div>
    <v-dialog
      v-model="show"
      max-width="90vh"
      transition="dialog-top-transition"
      @click:outside="closeRestorePassContactModal(false)"
    >
      <!-- @click:outside="closeModalUpdateContact()"⁄ -->
      <v-card rounded="lg">
        <v-card-title class="headline pa-5">
          ¿Actualizar Contraseña Asesor?
        </v-card-title>
        <v-card-text class="pa-10">
          <div class="py-4">
            Estas por reestablecer la contraseña de
            <span class="pa-2 delete-name">{{ name }} </span>
            recuerda notificarle el cambio.
          </div>
          <div>
            <v-form
              ref="restorePassContactForm"
              v-model="restorePassContactFormValid"
            >
              <v-row justify="space-between" align="center">
                <v-col cols="12" sm="6">
                  <div class="mb-3">
                    <h3>
                      Contraseña <span class="password">(Temporal) </span>
                    </h3>
                  </div>
                  <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    :disabled="isRestoringPassword"
                    type="password"
                    outlined
                    placeholder="Contraseña"
                    autocomplete="new-password"
                    required
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="mb-3">
                    <h3>
                      Confirmar contraseña
                      <span class="password">(Temporal) </span>
                    </h3>
                  </div>
                  <v-text-field
                    v-model="confirmPassword"
                    :rules="[
                      confirmPasswordRules.required,
                      confirmPasswordRule
                    ]"
                    :disabled="isRestoringPassword"
                    outlined
                    type="password"
                    placeholder="Confirmar contraseña"
                    autocomplete="new-password"
                    required
                  />
                </v-col>
                <v-col class="pb-10" cols="12">
                  <div class="warning--text">
                    <h4>
                      Por seguridad, recuerda avisar al asesor actualizar su
                      contraseña.
                    </h4>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
        <div v-if="messageError" class="red--text pa-5">
          {{ messageError }}
        </div>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            color="indigo darken-1"
            text
            :disabled="isRestoringPassword"
            @click="closeRestorePassContactModal(false)"
          >
            Cancelar
          </v-btn>
          <v-btn
            dark
            elevation="0"
            color="indigo darken-1"
            :disabled="isRestoringPassword"
            :loading="isRestoringPassword"
            @click="validateRestorePassContactForm()"
          >
            Restablecer Contraseña
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    contactToRestore: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      restorePassContactFormValid: true,
      messageError: "",
      isRestoringPassword: false,
      name: "",
      password: "",
      passwordRules: [v => !!v || "La contraseña es requerida"],
      confirmPassword: "",
      confirmPasswordRules: {
        required: value => !!value || "Requerido"
      }
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  watch: {
    contactToRestore(newValue) {
      if (newValue) {
        this.name = newValue.name ? newValue.name : "";
      } else {
        this.name = "";
      }
    }
  },
  methods: {
    ...mapActions({
      updateContactByIdStore: "contacts/updateContactById"
    }),
    confirmPasswordRule(v) {
      if (this.password === v) {
        return true;
      } else {
        return "Las contraseñas no coinciden";
      }
    },
    closeRestorePassContactModal(val) {
      this.password = "";
      this.confirmPassword = "";
      this.$refs.restorePassContactForm.resetValidation();
      this.show = val;
    },
    validateRestorePassContactForm() {
      //   this.AddContact();
      if (this.$refs.restorePassContactForm.validate()) {
        this.restorePassword();
      } else {
        this.messageError = "Por favor, verifica los errores.";
      }
    },
    restorePassword() {
      this.isRestoringPassword = true;
      this.messageError = "";
      this.updateContactByIdStore({
        role: this.contactToRestore.role,
        data: {
          _id: this.contactToRestore._id,
          user_id: this.contactToRestore.user_id,
          name: this.contactToRestore.name,
          email: this.contactToRestore.email,
          password: this.password,
          table: this.contactToRestore.role
        }
      }).then(res => {
        if (res.hasOwnProperty("user")) {
          this.isRestoringPassword = false;
          this.messageError = "";
          this.closeRestorePassContactModal(false);
        } else {
          this.messageError = "Algo salio mal, intentalo de nuevo.";
          this.isRestoringPassword = false;
        }
      });
    }
  }
};
</script>

<style scoped>
.v-dialog {
  border-radius: 24px !important;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: none !important;
}
.v-expansion-panel {
  box-shadow: none;
}
.delete-name {
  font-weight: 400;
  font-size: 34 px;
  color: #335ba5;
}
</style>
